import React, { createContext, useContext, useState } from 'react';

const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
  const [nextTrigger, setNextTrigger] = useState(false);
  const [backTrigger, setBackTrigger] = useState(false);

  return (
    <NavigationContext.Provider value={{ nextTrigger, setNextTrigger, backTrigger, setBackTrigger }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => {
  return useContext(NavigationContext);
};
