import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
container: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '40px',
  padding: '6px 8px',
  border: 'solid 2.5px #FFF',
  borderRadius: '10px',
  background: theme => theme.background,
  boxShadow: theme =>
    `0 0 0 2px ${theme.shadow},
     0 2px 0 3px ${theme.shadow}`,
  marginLeft: '15px',
  color: '#FFF',
},

label: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1rem',
  gap: '4px',
  lineHeight: 1, 
},

startIcon: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '4px',
},

endIcon: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: '4px',
}


}));

export default function CustomButton(props) {
  const {content, onClick, icon, startIcon, endIcon} = props;

  const findColor = (content) => {
    switch (content) {
      case 'Save':
      case 'Play':
        return {background: '#35D461', shadow: '#29A547'};
      case 'Logout':
        return {background: '#E7655A', shadow: '#963636'};
      case 'Back':
        return {background: '#29B6F6', shadow: '#1565C0'};
      case 'Next':
        return {background: '#FF9800', shadow: '#A84300'};
      case 'Reset':
        return {background: '#CB55FF', shadow: '#8200BC'};
    }
  }
  const classes = useStyles(findColor(content));

  return (
    <Button
      className={classes.container}
      onClick={onClick}
      startIcon={startIcon || icon}
      endIcon={endIcon}
        classes={{
          label: classes.label,
          startIcon: classes.startIcon,
          endIcon: classes.endIcon,
        }}
    >
      {content}
    </Button>
  );
}