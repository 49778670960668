import React from 'react';
import { Grid, Box, Button } from '@material-ui/core';
import styled from '@emotion/styled';

const SubmitButton = ({ onSubmit }) => {
    return (
        <ButtonGrid container spacing={3}>
            <GridItem item xs={6} sm={8} md={6} lg={4}>
                <StyledButton variant="contained" color="primary" onClick={onSubmit}>
                    SUBMIT
                </StyledButton>
            </GridItem>
        </ButtonGrid>
    );
};

export default SubmitButton;

const ButtonGrid = styled(Grid)`
 display:flex;
 justify-content: center;
 align-items: center;
`;

const GridItem = styled(Grid)`
@media (min-width:768px){
 max-width:15%;
}
`;

const StyledButton = styled(Button)`
  width: 75% !important; 
  height: 50px !important; 
  font-size: 18px !important;
`;